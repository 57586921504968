import * as React from 'react';
import GlobalStyles from '../../styles/globalStyles';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import HomePageTemplate from '../../templates/homePageTemplate';
import LanguageModal from '../../components/languageModal/languageModal';
import BackToTopScroll from '../../components/backToTopScroll/backToTopScroll';
import { navigate } from 'gatsby';
import { I18nextProvider } from 'react-i18next';
import i18next from '../../i18next';
import { Router, Redirect, useLocation } from '@reach/router';
import { validHomePageRoutes } from '../../constants';
import {
  addTrailingSlash,
  isValidURLLanguage,
  isValidURLRegion,
  validNodeLocale,
} from '../../utils';
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';

const IndexPageFR = ({ location }) => {
  const locationHook = useLocation();
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    setLoaded(true);
    window.scrollTo(0, 0);
    if (location?.href !== location?.href?.toLowerCase()) {
      window.location.href = location?.href?.toLowerCase();
    }
    if (!validHomePageRoutes?.includes(location?.pathname)) {
      navigate('/404/');
    }
  }, []);

  React.useLayoutEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const cookies = new Cookies();
  const date = new Date();
  const urlLanguage = location?.pathname?.split('/')?.find((lang) => lang);
  const queryParams = new URLSearchParams(location?.search);
  const regionQueryParams = queryParams.get('region')?.toUpperCase();

  typeof window !== 'undefined' &&
    window?.history?.replaceState({}, document?.title, location?.pathname);

  const updateAndSetRegionCookie = () => {
    if (
      isValidURLRegion(regionQueryParams) &&
      cookies.get('region')?.toUpperCase() !== regionQueryParams
    ) {
      cookies.set('region', regionQueryParams?.toLowerCase(), {
        path: '/',
        expires: new Date(
          date.getFullYear() + 1,
          date.getMonth(),
          date.getDate()
        ),
      });
      return regionQueryParams;
    }
  };
  const selectedRegion =
    isValidURLRegion(regionQueryParams) &&
    cookies.get('region')?.toUpperCase() !== regionQueryParams
      ? updateAndSetRegionCookie()
      : cookies.get('region')?.toUpperCase()
      ? cookies.get('region')?.toUpperCase()
      : 'NB';

  const updateAndSetLanguageCookie = (language) => {
    if (language && cookies.get('language') !== language) {
      cookies.set('language', language, {
        path: '/',
        expires: new Date(
          date.getFullYear() + 1,
          date.getMonth(),
          date.getDate()
        ),
      });
      return language;
    }
  };
  const selectedLanguage = 'fr-CA';

  return !loaded ? (
    <>
      <I18nextProvider i18n={i18next}>
        <Helmet>
          <link
            rel="alternate"
            href={addTrailingSlash(process.env.GATSBY_DOMAIN_URL + '/en')}
            hreflang="en-ca"
          ></link>
          <link
            rel="alternate"
            href={addTrailingSlash(process.env.GATSBY_DOMAIN_URL + '/fr')}
            hreflang="fr-ca"
          ></link>
        </Helmet>
        <GlobalStyles />
        <Header englishSlug="/en" frenchSlug="/fr" />

        <HomePageTemplate
          selectedLanguage={selectedLanguage}
          selectedRegion={selectedRegion}
        />

        <Footer />
      </I18nextProvider>
    </>
  ) : (
    <>
      <I18nextProvider i18n={i18next}>
        <GlobalStyles />
        <LanguageModal />
        <Helmet>
          <link
            rel="alternate"
            href={addTrailingSlash(process.env.GATSBY_DOMAIN_URL + '/en')}
            hreflang="en-ca"
          ></link>
          <link
            rel="alternate"
            href={addTrailingSlash(process.env.GATSBY_DOMAIN_URL + '/fr')}
            hreflang="fr-ca"
          ></link>
        </Helmet>

        <Header englishSlug="/en" frenchSlug="/fr" />
        <HomePageTemplate
          selectedLanguage={selectedLanguage}
          selectedRegion={selectedRegion}
          path="fr"
        />
        <Footer />
        <BackToTopScroll />
      </I18nextProvider>
    </>
  );
};

export default IndexPageFR;
